/* eslint-disable jsx-a11y/anchor-is-valid */
import {toAwsS3Url} from '../../../../../_metronic/helpers'

export function SelectedRole() {
  return (
    <div>
      <div className='d-flex flex-row-fluid flex-column text-center pb-5'>
        <div className='py-9 mb-5'>
          <img alt='Logo' src={toAwsS3Url('/media/logos/gk-main-logo.svg')} className='h-60px' />
        </div>
      </div>
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In</h1>
        {/* <div className='text-gray-400 fw-bold fs-4'>
          Or Register
          <Link
            to='/auth/forgot-password'
            className='link-primary fs-6 fw-bolder'
            style={{marginLeft: '5px'}}
          >
            Your School
          </Link>
        </div> */}
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-xl-4'>
            <a
              href='/teacher/auth/login'
              target='_self'
              className='card card-xl-stretch mb-xl-12 cursor-pointer'
            >
              <div className='card-body d-flex flex-column flex-stack mb-5 pb-0 px-0 bg-light'>
                <div className='text-dark fs-2 text-left mb-10 fw-bolder card-px'>Teacher</div>
                <div className='position-relative align-self-center'>
                  <img
                    alt='Teacher Category'
                    style={{marginBottom: '-5px'}}
                    src={toAwsS3Url('/media/illustrations/sigma-1/7.png')}
                    className='mw-100 mx-auto h-200px card-rounded'
                  />
                </div>
              </div>
            </a>
          </div>
          <div className='col-xl-4'>
            <a
              href='/student/auth/login'
              target='_self'
              className='card card-xl-stretch mb-xl-12 cursor-pointer'
            >
              <div className='card-body d-flex flex-column flex-stack mb-5 pb-0 px-0 bg-light'>
                <div className='text-dark fs-2 text-left mb-10 fw-bolder card-px'>Student</div>
                <div className='position-relative align-self-center'>
                  <img
                    alt='Teacher Category'
                    style={{marginBottom: '-5px'}}
                    src={toAwsS3Url('/media/illustrations/sigma-1/11.png')}
                    className='mw-100 mx-auto h-200px card-rounded'
                  />
                </div>
              </div>
            </a>
          </div>
          <div className='col-xl-4'>
            <a
              href='/parent/auth/login'
              target='_self'
              className='card card-xl-stretch mb-xl-12 cursor-pointer'
            >
              <div className='card-body d-flex flex-column flex-stack mb-5 pb-0 px-0 bg-light'>
                <div className='text-dark fs-2 text-left mb-10 fw-bolder card-px'>Parent</div>
                <div className='position-relative align-self-center'>
                  <img
                    alt='Teacher Category'
                    style={{marginBottom: '-5px'}}
                    src={toAwsS3Url('/media/illustrations/sigma-1/6.png')}
                    className='mw-100 mx-auto h-200px card-rounded'
                  />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className='text-center'>
        <div className='text-gray-400 fw-bold fs-4'>
          Are you School Admin?
          <a
            className='link-primary fs-6 fw-bolder cursor-pointer'
            style={{marginLeft: '5px'}}
            href='/admin/auth/login'
            target='_self'
          >
            Log In here
          </a>
        </div>
      </div>
    </div>
  )
}

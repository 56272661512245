import {Route} from 'react-router-dom'
import {IRoutes} from 'app/config/routers/routers.model'

export const generateRoutesComponents = (routes: IRoutes[]) =>
  routes.map(({component: Component, ...route}) => (
    <Route
      key={route.path}
      path={route.path}
      exact={route.exactPath}
      render={(props: any) => <Component {...props} />}
    />
  ))

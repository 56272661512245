export const ActionTypes = {
  GET_THEME: 'GET_THEME',
}

export interface ITheme {
  id: number
  name: string
  login_page_image: string
  school_logo: string
}

export interface IThemeState {
  theme: ITheme
}

export interface IThemeResponse {
  data: ITheme
}

import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {RoleTypes} from 'app/config/routers/routers.model'
import {AuthUserModel} from '../../models/AuthModel'
export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  AuthLogin: '[Auth Login] Action',
  AuthLogout: '[Logout] Action',
  SetUser: '[Set User] Action',
}

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  selectedRole: undefined,
}

export interface IAuthState {
  user?: AuthUserModel
  accessToken?: string
  selectedRole?: RoleTypes
}

export const reducer = persistReducer(
  {storage, key: 'gk-auth', whitelist: ['user', 'accessToken', 'selectedRole']},
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.AuthLogin: {
        const accessToken = action.payload?.accessToken
        const user = action.payload?.user
        return {accessToken, user: user, selectedRole: action.payload?.selectedRole || undefined}
      }

      case actionTypes.AuthLogout: {
        return initialAuthState
      }

      default:
        return state
    }
  }
)

export const actions = {
  authLogin: (accessToken: string, user: AuthUserModel, selectedRole: RoleTypes) => ({
    type: actionTypes.AuthLogin,
    payload: {accessToken, user, selectedRole},
  }),
  authLogout: () => ({type: actionTypes.AuthLogout}),
}

export function* saga() {}

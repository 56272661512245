import axios from 'axios'
import {AuthLoginResponseModel} from '../../models/AuthModel'

const API_URL = process.env.REACT_APP_API_URL

export const AUTH_LOGIN_URL = `${API_URL}/account/login`
export const FORGOT_PASSWORD_URL = `${API_URL}/account/forgot_password`

// Server should return AuthLoginResponseModel
export function authLogin(email: string, password: string) {
  return axios.post<AuthLoginResponseModel>(AUTH_LOGIN_URL, {
    email,
    password,
  })
}

export function forgotPassword(email: string) {
  return axios.post<AuthLoginResponseModel>(FORGOT_PASSWORD_URL, {
    email,
  })
}

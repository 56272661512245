/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ForgotPassword} from './components/forgot-password/ForgotPassword'
import {BasicLayout, CustomLayout} from 'app/components'
import {SelectedRoleCustom} from './components/login/SelectedRoleCustom'
import {ActionTypes, getTheme} from 'app/modules/auth/redux/landing-page'
import {SelectedRole} from './components/login/SelectedRole'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from 'setup'
import {createLoadingSelector} from 'setup/redux/loading.toolkit'
import {toAwsS3Url} from '_metronic/helpers'

export function AuthPage() {
  const dispatch = useDispatch()
  const theme = useSelector((state: RootState) => state.landing.theme)
  const loading = useSelector(createLoadingSelector(ActionTypes.GET_THEME))

  const Layout: FC<{children: JSX.Element[] | JSX.Element}> = ({children}) => {
    if (!loading) {
      if (theme.login_page_image !== '') {
        return <CustomLayout theme={theme}>{children}</CustomLayout>
      } else if (theme.login_page_image === '') {
        return <BasicLayout>{children}</BasicLayout>
      }
    }

    return (
      <div id='splash-screen' className='splash-screen'>
        <img
          src={toAwsS3Url('/media/logos/gk-main-logo.svg')}
          alt='Paideia Logo'
          className='h-30px'
        />
        <span>Loading ...</span>
      </div>
    )
  }

  useEffect(() => {
    dispatch(getTheme())
  }, [dispatch])

  return (
    <Layout>
      <Switch>
        <Route
          exact
          path='/auth/selected-role'
          component={theme.login_page_image !== '' ? SelectedRoleCustom : SelectedRole}
        />
        <Route path='/auth/forgot-password' component={ForgotPassword} />
        <Redirect from='/auth' exact={true} to='/auth/selected-role' />
        <Redirect to='/auth/selected-role' />
      </Switch>
    </Layout>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {toAwsS3Url} from '../../../../../_metronic/helpers'

export function VerifyEmail() {
  const location: any = useLocation()

  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-column-fluid'>
        <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-10'>
          <Link to='/auth/forgot-password' className='mb-10'>
            <img
              alt='Logo'
              src={toAwsS3Url('/media/logos/gk-main-logo.svg')}
              className='h-40px mb-5'
            />
          </Link>
          <div className='pt-lg-10 mb-10'>
            <h1 className='fw-bolder fs-2qx text-gray-800 mb-7'>Verify Your Email</h1>
            <div className='fs-3 fw-bold text-muted mb-10'>
              We Have Sent an Email To{' '}
              <span className='link-primary fw-bolder'>{location.state.email}</span>
              <br />
              Pelase Follow a Link To Activated Your Account
            </div>
            <div className='text-center mb-10'>
              <Link to='/auth' className='btn btn-lg btn-primary fw-bolder'>
                Sign In Now
              </Link>
            </div>
            <div className='fs-5'>
              <span className='fw-bold text-gray-700'>Didn’t Receive an Email? </span>
              <Link to='/auth/forgot-password' className='link-primary fw-bolder'>
                Resend
              </Link>
            </div>
          </div>
          <div
            className='d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px'
            style={{
              backgroundImage: `url(${toAwsS3Url('/media/illustrations/sigma-1/13.png')})`,
            }}
          />
        </div>
      </div>
    </div>
  )
}

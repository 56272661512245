import { setStatusMaintance } from 'setup/redux/features/status-maintance'

export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken},
      } = store.getState()

      if (accessToken) {
        config.headers['auth-gk2'] = `jwt ${accessToken}`
      }
      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use(
    (response: any) => {
      if((response.config.url === '/status_maintance') || (response.status === 202 && response.data?.message === "Maintance")){
        store.dispatch(
          setStatusMaintance(
            response?.data?.data
          )
        )
      }
      return response
    },
    (error: any) => {
      return Promise.reject(error)
    }
  )
}

export const toAbsoluteUrl = (pathname: string) => {
  if (pathname.startsWith('http')) {
    return pathname
  }
  return process.env.PUBLIC_URL + pathname
}

export const toAwsS3Url = (pathname: string) => {
  if (pathname.startsWith('http')) {
    return pathname
  }
  return process.env.REACT_APP_S3_STATIC + pathname
}

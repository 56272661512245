import React, {FC, useEffect, useRef, useState} from 'react'
import {KTSVG, toAwsS3Url} from '../../../_metronic/helpers'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Step4} from './steps/Step4'
import {Step5} from './steps/Step5'
import {StepperComponent} from '../../../_metronic/assets/ts/components'
import {Formik, Form, FormikValues} from 'formik'
import {ICreateAccount, createAccountSchemas, inits} from './CreateAccountWizardHelper'

const VerticalRegistration: FC = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
    }
  }

  useEffect(() => {
    document.body.classList.add('bg-body')

    if (!stepperRef.current) {
      return () => {
        document.body.classList.remove('bg-body')
      }
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div className="d-flex flex-column flex-root">
      <div ref={stepperRef} className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column" id="kt_create_account_stepper">
        <div className="d-flex flex-column flex-lg-row-auto w-xl-500px bg-lighten shadow-sm">
          <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-500px scroll-y">
            <div className="d-flex flex-row-fluid flex-column flex-center p-10 pt-lg-20">
              <div className="mb-10 mb-lg-20">
                <img alt='Logo' src={toAwsS3Url('/media/logos/logo-2.svg')} className='h-40px' />
              </div>
              <div className='stepper-nav'>
                <div className='stepper-item current' data-kt-stepper-element='nav'>
                  <div className='stepper-line w-40px'></div>

                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>1</span>
                  </div>

                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Set Up Profile</h3>

                    <div className='stepper-desc fw-bold'>Complete Your Account Details</div>
                  </div>
                </div>

                <div className='stepper-item' data-kt-stepper-element='nav'>
                  <div className='stepper-line w-40px'></div>

                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>2</span>
                  </div>

                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Set Up School</h3>
                    <div className='stepper-desc fw-bold'>Complete Your School Details</div>
                  </div>
                </div>

                <div className='stepper-item' data-kt-stepper-element='nav'>
                  <div className='stepper-line w-40px'></div>

                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>3</span>
                  </div>

                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Curriculum Information</h3>
                    <div className='stepper-desc fw-bold'>Complete Your Curriculum Detils</div>
                  </div>
                </div>

                <div className='stepper-item' data-kt-stepper-element='nav'>
                  <div className='stepper-line w-40px'></div>

                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>4</span>
                  </div>

                  <div className='stepper-label'>
                    <h3 className='stepper-title'>School Information</h3>
                    <div className='stepper-desc fw-bold'>Complete Numbers of User</div>
                  </div>
                </div>

                <div className='stepper-item' data-kt-stepper-element='nav'>
                  <div className='stepper-line w-40px'></div>

                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>5</span>
                  </div>

                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Completed</h3>
                    <div className='stepper-desc fw-bold'>Review and Submit</div>
                  </div>
                </div>
              </div>
            </div>
            {/* begin::Illustration */}
            <div className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-150px min-h-lg-300px" style={{ backgroundImage: `url(${toAwsS3Url('/media/illustrations/sigma-1/16.png')})`}} />
            {/* endOf::Illustration */}
          </div>
        </div>

        <div className="d-flex flex-column flex-lg-row-fluid py-10">
          <div className="d-flex flex-center flex-column flex-column-fluid">
            <div className="w-lg-700px mx-auto">
              <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
                {() => (
                  <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
                    <div className='current' data-kt-stepper-element='content'>
                      <Step1 />
                    </div>

                    <div data-kt-stepper-element='content'>
                      <Step2 />
                    </div>

                    <div data-kt-stepper-element='content'>
                      <Step3 />
                    </div>

                    <div data-kt-stepper-element='content'>
                      <Step4 />
                    </div>

                    <div data-kt-stepper-element='content'>
                      <Step5 />
                    </div>

                    <div className='d-flex flex-stack pt-10'>
                      <div className='mr-2'>
                        <button
                          onClick={prevStep}
                          type='button'
                          className='btn btn-lg btn-light-primary me-3'
                          data-kt-stepper-action='previous'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr063.svg'
                            className='svg-icon-4 me-1'
                          />
                          Back
                        </button>
                      </div>

                      <div>
                        <button type='submit' className='btn btn-lg btn-primary me-3'>
                          <span className='indicator-label'>
                            {stepper.current?.currentStepIndex !==
                              stepper.current?.totatStepsNumber! - 1 && 'Continue'}
                            {stepper.current?.currentStepIndex ===
                              stepper.current?.totatStepsNumber! - 1 && 'Submit'}
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr064.svg'
                              className='svg-icon-3 ms-2 me-0'
                            />
                          </span>
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div
    //   ref={stepperRef}
    //   className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
    //   id='kt_create_account_stepper'
    // >
    //   <div className='d-flex justify-content-center bg-white rounded justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
    //     <div className='px-6 px-lg-10 px-xxl-15 py-20'>
    //       <div className='stepper-nav'>
    //         <div className='stepper-item current' data-kt-stepper-element='nav'>
    //           <div className='stepper-line w-40px'></div>

    //           <div className='stepper-icon w-40px h-40px'>
    //             <i className='stepper-check fas fa-check'></i>
    //             <span className='stepper-number'>1</span>
    //           </div>

    //           <div className='stepper-label'>
    //             <h3 className='stepper-title'>Account Type</h3>

    //             <div className='stepper-desc fw-bold'>Setup Your Account Details</div>
    //           </div>
    //         </div>

    //         <div className='stepper-item' data-kt-stepper-element='nav'>
    //           <div className='stepper-line w-40px'></div>

    //           <div className='stepper-icon w-40px h-40px'>
    //             <i className='stepper-check fas fa-check'></i>
    //             <span className='stepper-number'>2</span>
    //           </div>

    //           <div className='stepper-label'>
    //             <h3 className='stepper-title'>Account Settings</h3>
    //             <div className='stepper-desc fw-bold'>Setup Your Account Settings</div>
    //           </div>
    //         </div>

    //         <div className='stepper-item' data-kt-stepper-element='nav'>
    //           <div className='stepper-line w-40px'></div>

    //           <div className='stepper-icon w-40px h-40px'>
    //             <i className='stepper-check fas fa-check'></i>
    //             <span className='stepper-number'>3</span>
    //           </div>

    //           <div className='stepper-label'>
    //             <h3 className='stepper-title'>Business Info</h3>
    //             <div className='stepper-desc fw-bold'>Your Business Related Info</div>
    //           </div>
    //         </div>

    //         <div className='stepper-item' data-kt-stepper-element='nav'>
    //           <div className='stepper-line w-40px'></div>

    //           <div className='stepper-icon w-40px h-40px'>
    //             <i className='stepper-check fas fa-check'></i>
    //             <span className='stepper-number'>4</span>
    //           </div>

    //           <div className='stepper-label'>
    //             <h3 className='stepper-title'>Billing Details</h3>
    //             <div className='stepper-desc fw-bold'>Set Your Payment Methods</div>
    //           </div>
    //         </div>

    //         <div className='stepper-item' data-kt-stepper-element='nav'>
    //           <div className='stepper-line w-40px'></div>

    //           <div className='stepper-icon w-40px h-40px'>
    //             <i className='stepper-check fas fa-check'></i>
    //             <span className='stepper-number'>5</span>
    //           </div>

    //           <div className='stepper-label'>
    //             <h3 className='stepper-title'>Completed</h3>
    //             <div className='stepper-desc fw-bold'>Woah, we are here</div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   <div className='d-flex flex-row-fluid flex-center bg-white rounded'>
    //     <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
    //       {() => (
    //         <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
    //           <div className='current' data-kt-stepper-element='content'>
    //             <Step1 />
    //           </div>

    //           <div data-kt-stepper-element='content'>
    //             <Step2 />
    //           </div>

    //           <div data-kt-stepper-element='content'>
    //             <Step3 />
    //           </div>

    //           <div data-kt-stepper-element='content'>
    //             <Step4 />
    //           </div>

    //           <div data-kt-stepper-element='content'>
    //             <Step5 />
    //           </div>

    //           <div className='d-flex flex-stack pt-10'>
    //             <div className='mr-2'>
    //               <button
    //                 onClick={prevStep}
    //                 type='button'
    //                 className='btn btn-lg btn-light-primary me-3'
    //                 data-kt-stepper-action='previous'
    //               >
    //                 <KTSVG
    //                   path='/media/icons/duotune/arrows/arr063.svg'
    //                   className='svg-icon-4 me-1'
    //                 />
    //                 Back
    //               </button>
    //             </div>

    //             <div>
    //               <button type='submit' className='btn btn-lg btn-primary me-3'>
    //                 <span className='indicator-label'>
    //                   {stepper.current?.currentStepIndex !==
    //                     stepper.current?.totatStepsNumber! - 1 && 'Continue'}
    //                   {stepper.current?.currentStepIndex ===
    //                     stepper.current?.totatStepsNumber! - 1 && 'Submit'}
    //                   <KTSVG
    //                     path='/media/icons/duotune/arrows/arr064.svg'
    //                     className='svg-icon-3 ms-2 me-0'
    //                   />
    //                 </span>
    //               </button>
    //             </div>
    //           </div>
    //         </Form>
    //       )}
    //     </Formik>
    //   </div>
    // </div>
  )
}

export {VerticalRegistration}

/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect, useMemo} from 'react'
import {Switch, Route, useLocation} from 'react-router-dom'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {PublicRoutes} from './PublicRoutes'
import {Logout} from '../modules/auth/redux/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import {MasterInit} from '../../_metronic/layout/MasterInit'
import JwtDecode from 'jwt-decode'
import * as auth from '../modules/auth/redux/auth/AuthRedux'
import PATH from 'app/config/path/path'
import {MaintancePage} from 'app/pages/maintance-page/MaintancePage'
import {getStatusMaintance} from 'setup/redux/features/status-maintance'
import ReactGA from 'react-ga4'
import InitializeReactGA from '../helper/googleAnalytics'
import {isProduction} from 'app/services/environment'

function usePageViews() {
  const location = useLocation()
  useEffect(() => {
    if (isProduction()) {
      InitializeReactGA(ReactGA)
      ReactGA.send({
        hitType: 'pageview',
        page: window.location?.href || window?.location?.pathname,
        title: window.location?.href || window?.location?.pathname,
      })
    }
  }, [location.pathname])
}
const Routes: FC = () => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const token: any = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)
  const statusMaintance = useSelector((state: RootState) => state.statusMaintance.status_maintance)
  const dispatch = useDispatch()
  const selectedRole = useSelector<RootState, string>((state) => state.auth?.selectedRole || '')
  const mainPageByRole = useMemo(() => {
    switch (selectedRole.toLowerCase()) {
      case 'admin school':
        return '/admin'
      case 'teacher':
        return '/teacher'
      case 'student':
        return '/student'
      case 'parent':
        return '/parent'
      default:
        return ''
    }
  }, [selectedRole])
  useEffect(() => {
    if (token !== undefined) {
      const decoded: any = JwtDecode(token)
      if (decoded.exp * 1000 < Date.now()) dispatch(auth.actions.authLogout())
    }
  }, [dispatch, token])
  useEffect(() => {
    if (isAuthorized && window.location.pathname !== PATH.GOOGLE_OAUTH_PAGE) {
      try {
        const mainpage = localStorage.getItem('gk:main') || mainPageByRole
        const redirectAttemp = parseInt(localStorage.getItem('redirect-attemp') || '0')
        if (mainpage && +(redirectAttemp || 0) < 5) {
          window.open(mainpage, '_self')
          localStorage.setItem('redirect-attemp', (redirectAttemp + 1).toString())
        }
      } catch (err) {}
    }
  }, [isAuthorized]) //eslint-disable-line
  usePageViews()
  useEffect(() => {
    getStatusMaintance()
  }, [])
  return (
    <>
      <Switch>
        {statusMaintance && <Route path='/' component={MaintancePage} />}
        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <PublicRoutes />
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <></>
        )}

        <Route path='/error' component={ErrorsPage} />
        <Route path='/logout' component={Logout} />
        <Route component={ErrorsPage} />
      </Switch>
      <MasterInit />
    </>
  )
}

export {Routes}

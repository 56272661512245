import React, {useEffect, useRef} from 'react'
import addEventParentIframe from 'app/services/addEventParentIframe'

const OAuthGoogle: React.FC = () => {
  const buttonGoogle = useRef<any>()
  const loadScript = (d: any, s: string, id: string, jsSrc: string, cb: (data: any) => void) => {
    const element = d.getElementsByTagName(s)[0]
    let js = element
    js = d.createElement(s)
    js.id = id
    js.src = jsSrc
    js.setAttribute('async', '')
    js.setAttribute('defer', '')
    let div = element
    div = d.createElement('div')
    div.id = 'g_id_onload'
    div.setAttribute('data-client_id', `${process.env.REACT_APP_GOOGLE_OAUTH_KEY}`)
    window.handleCredentialResponseGoogle = cb
    div.setAttribute('data-auto_prompt', 'false')
    div.setAttribute('data-auto_select', 'false')
    div.setAttribute('data-ux_mode', 'popup')
    div.setAttribute('data-callback', 'handleCredentialResponseGoogle')
    d.body.appendChild(js)
    d.body.appendChild(div)
    console.log(buttonGoogle.current)
  }
  const handleSigninSuccess = (res: any) => {
    const respondSignin = {
      tokenId: res.tokenId,
      googleId: res.sub,
      profileObj: {
        googleId: res.sub,
        imageUrl: res.picture,
        email: res.email,
        name: res.name,
        givenName: res.given_name,
        familyName: res.family_name,
      },
    }
    addEventParentIframe('googleLogin', respondSignin)
  }

  useEffect(() => {
    loadScript(
      document,
      'script',
      'google-login',
      'https://accounts.google.com/gsi/client',
      (data: any) => {
        console.log('aslkdjf', data)
        const resData = data?.credential?.split('.') || ['', '']
        const tokenResponse = JSON.parse(window.atob(resData[1]) || '{}')
        handleSigninSuccess({tokenId: data?.credential, ...tokenResponse})
      }
    )
  }, [])

  return (
    <div className='bg-white h-100 w-100 d-flex justify-content-center align-items-start'>
      <div
        className='g_id_signin'
        data-type='standard'
        data-shape='rectangular'
        data-theme=''
        data-text='signin_with'
        data-size='large'
        data-logo_alignment='left'
        data-width='100'
      ></div>
    </div>
  )
}

export default OAuthGoogle

import {useMemo} from 'react'
import {Redirect, Switch} from 'react-router-dom'
import {generateRoutesComponents} from 'app/services/routes/routes.service'
import Routes from 'app/config/routers/routes'

export function PublicRoutes() {
  const routesComponents = useMemo(
    () => generateRoutesComponents(Routes.public?.user ? Routes.public?.user[0].routes : []),
    []
  )
  return (
    <Switch>
      {routesComponents}
      <Redirect to='/auth' />
    </Switch>
  )
}

import {FC, useMemo} from 'react'
import {IDialogConfirmationProps} from './DialogConfirmation.model'
import Dialog from '../Dialog/Dialog'
import clsx from 'clsx'

const DialogConfirmation: FC<IDialogConfirmationProps> = ({
  children,
  title,
  show,
  isLoading = false,
  onConfirm,
  onCancel,
  mode,
  modeCancel,
  captions,
  showCancelBtn,
}) => {
  const btnClassName = useMemo(() => {
    if (mode === 'danger') {
      return 'btn-danger'
    }
    return 'btn-primary'
  }, [mode])
  const btnCancelClassName = useMemo(() => {
    if (modeCancel === 'danger') {
      return 'btn-danger'
    }
    return 'btn-primary'
  }, [modeCancel])
  return (
    <Dialog title={title} show={show}>
      <div>
        <div className='mb-3'>{children}</div>
        <div className='d-flex justify-content-center mt-8'>
          {showCancelBtn && (
            <button
              className={clsx('w-25 mx-1 btn btn-sm', btnCancelClassName)}
              disabled={isLoading}
              onClick={() => {
                if (onCancel) onCancel()
              }}
            >
              {captions?.cancel || 'Cancel'}
            </button>
          )}
          <button
            className={clsx('w-25 mx-1 btn btn-sm', btnClassName)}
            onClick={() => {
              if (onConfirm) onConfirm()
            }}
            disabled={isLoading}
          >
            <span> {captions?.confirm || 'OK'}</span>
            {isLoading && (
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            )}
          </button>
        </div>
      </div>
    </Dialog>
  )
}
export default DialogConfirmation

import {sagaRequestService} from 'app/services/sagaRequest'
import {ActionTypes} from './landingPage.model'
import {takeLatest} from 'redux-saga/effects'
import {getTheme} from './landingPage.actions'

function* getThemeSaga() {
  yield sagaRequestService({
    requestPayload: {
      url: `/landing_page/theme`,
      method: 'GET',
    },
    actionType: ActionTypes.GET_THEME,
  })
}

export default function* landingPageSaga() {
  yield takeLatest(getTheme.type, getThemeSaga)
}

import clsx from 'clsx'
import {toAwsS3Url} from '_metronic/helpers'
import {RootState} from 'setup'
import {useSelector} from 'react-redux'
const MaintancePage = ({isLoading}: {isLoading: boolean}) => {
  const statusMaintance = useSelector((state: RootState) => state.statusMaintance)
  return (
    <div className={clsx('position-fixed w-100 h-100')}>
      <div className={clsx('card bg-white shadow-sm rounded-lg mw-1000px mx-auto  float-to-center')}>
        <div className='card-body d-flex flex-column justify-content-center align-items-center'>
          <img
            src={toAwsS3Url('/media/illustrations/sigma_pro/magnifying.svg')}
            className='w-300px'
            alt='illustration please waiting'
          />
          {isLoading ? (
            <span className='spinner-border fs-3x spinner-border-sm align-middle me-2 h-50px w-50px'></span>
          ) : (
            <>
              <div className='fs-1 fw-bolder my-2'>{statusMaintance.title}</div>
              <div className='fs-3 text-center'>{statusMaintance.message}</div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export {MaintancePage}

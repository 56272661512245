/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Field, ErrorMessage} from 'formik'

const Step1: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          Create Admin Account
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i>
        </h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Already have an Account?
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Sign In
          </a>
          .
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>First Name</label>
        <Field name='firstName' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='firstName' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Last Name</label>
        <Field name='lastName' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='lastName' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Email</label>
        <Field name='email' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='email' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Phone Number</label>
        <Field name='phoneNumber' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='phoneNumber' />
        </div>
      </div>

    </div>
  )
}

export {Step1}

import {FC} from 'react'
import {DialogConfirmation} from '../../../../components'

export interface ILoginDialogErrorProps {
  showError: boolean
  errorMessage: string
  handleConfirm: Function
  showCancelBtn: boolean
}

const LoginDialogError: FC<ILoginDialogErrorProps> = ({
  showError,
  errorMessage,
  handleConfirm,
  showCancelBtn,
}) => {
  const showErrorDialog = () => {
    handleConfirm()
  }

  return (
    <DialogConfirmation
      show={showError}
      title=''
      onConfirm={() => {
        showErrorDialog()
      }}
      showCancelBtn={showCancelBtn}
    >
      <div className='d-flex flex-column justify-content-center align-items-center'>
        <img
          src='/media/illustrations/sigma_pro/remove.svg'
          className='w-200px img-fluid mb-5'
          alt='img-warn-delete'
        />
        <div className='mb-5 text-capitalize text-dark-400 fw-bold fs-6'>{errorMessage}</div>
      </div>
    </DialogConfirmation>
  )
}

export default LoginDialogError

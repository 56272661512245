import * as Yup from 'yup'

export interface ICreateAccount {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  schoolName: string
  schoolAddress: string
  province: string
  city: string
  curriculumType: string
  numberStudent: string
  numberStaff: string
}

const createAccountSchemas = [
  Yup.object({
    firstName: Yup.string().required().label('First Name'),
    lastName: Yup.string().required().label('Last Name'),
    email: Yup.string().required().label('Email'),
    phoneNumber: Yup.string().required().label('Phone Number'),
  }),
  Yup.object({
    schoolName: Yup.string().required().label('School Name'),
    schoolAddress: Yup.string().required().label('School Address'),
    province: Yup.string().required().label('Province'),
    city: Yup.string().required().label('City'),
  }),
  Yup.object({
    numberStudent: Yup.string().required().label('Number of Student'),
    numberStaff: Yup.string().required().label('Number of Teacher/Staff'),
  })
]

const inits: ICreateAccount = {
  firstName: 'budi',
  lastName: 'anduk',
  email: 'budi@school.com',
  phoneNumber: '0856123456',
  schoolName: 'sd tangerang 1',
  schoolAddress: 'jalan raya jambu dua nomor 18',
  province: '1',
  city: '1',
  curriculumType: '1',
  numberStudent: '300',
  numberStaff: '50'
}

export {createAccountSchemas, inits}

import {actionRequest} from 'setup/redux/loading.toolkit'
import {ActionTypes, IStatusMaintance} from './index'
import {createAction} from '@reduxjs/toolkit'
import {apiRequestService} from 'app/services/apiRequest'
export const getStatusMaintance = () => {
  return apiRequestService({
    method: 'get',
    url: '/status_maintance',
    data: {},
  })
}
export const setStatusMaintance = createAction<IStatusMaintance>(
  actionRequest(ActionTypes.SET_STATUS_MAITANCE)
)

/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import clsx from 'clsx'
import {useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import {toAwsS3Url} from '../../../../../_metronic/helpers'
import {forgotPasswordSchema} from '../../models/ValidationSchema'
import {forgotPassword} from '../../redux/auth/AuthCRUD'
import LoginDialogError from '../login/LoginDialogError'

const initialValues = {email: ''}

export function ForgotPassword() {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      setSubmitting(true)
      forgotPassword(values.email)
        .then((fetch) => {
          setLoading(false)
          const {email} = fetch.data.data
          goToPasswordConfirmation(email)
        })
        .catch((err) => {
          setLoading(false)
          setSubmitting(false)
          setShowError(true)
          setErrorMessage(err.response.data.message)
        })
    },
  })

  const goToSelectedRole = () => {
    history.push('/auth/selected-role')
  }

  const goToPasswordConfirmation = (email: string) => {
    history.push({
      pathname: '/password-confirmation',
      state: {
        email,
      },
    })
  }

  const handleConfirm = () => {
    setShowError(false)
    setErrorMessage('')
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_forgot_password_form'
    >
      <div className='d-flex flex-row-fluid flex-column text-center pb-5'>
        <div className='py-9 mb-5'>
          <img alt='Logo' src={toAwsS3Url('/media/logos/gk-main-logo.svg')} className='h-60px' />
        </div>
      </div>
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Forgot Password ?</h1>
        <div className='text-gray-400 fw-bold fs-4'>Enter Your Email To Reset Your Password.</div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark required'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>

      <div className='text-center'>
        <div className='fv-row row'>
          <div className='col-lg-6'>
            <button
              type='button'
              id='kt_sign_in_back'
              className='btn btn-lg btn-danger w-100 mb-5'
              disabled={loading}
              onClick={goToSelectedRole}
            >
              {!loading && <span className='indicator-label'>Back</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          <div className='col-lg-6'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={loading || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Continue</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* show modal when error */}
      <LoginDialogError
        showError={showError}
        errorMessage={errorMessage}
        handleConfirm={handleConfirm}
        showCancelBtn={false}
      />
    </form>
  )
}

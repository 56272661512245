declare global {
  interface Window {
    GA_INITIALIZED: any
  }
}

function InitializeReactGA(ReactGA: any) {
  if (!window.GA_INITIALIZED && process.env.REACT_APP_GA_MEASUREMENT_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID)
    window.GA_INITIALIZED = true
  }
}

export default InitializeReactGA

import {createReducer} from '@reduxjs/toolkit'
import {
  IStatusMaintance,
  setStatusMaintance
} from './index'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export const initialState: IStatusMaintance = {
  status_maintance:false,
  title: '',
  message: '',
}

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setStatusMaintance, (state, action) => {
      state.status_maintance = action.payload.status_maintance
      state.title = action.payload.title
      state.message = action.payload.message
    })
    
)
export default persistReducer(
  {storage, key: 'gk-maintance', whitelist: ['status_maintance','title','message']},
  reducer
)

import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'
import {loadingReducer} from './loading.toolkit'
import * as auth from 'app/modules/auth/redux/auth'
import {statusMaintanceReducer} from './features/status-maintance'
import {landingPageReducer, landingPageSaga} from '../../app/modules/auth/redux/landing-page'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  statusMaintance: statusMaintanceReducer,
  landing: landingPageReducer,
  loading: loadingReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga(), landingPageSaga()])
}

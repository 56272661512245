import {FC} from 'react'
import {toAwsS3Url} from '../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'

const Step3: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          Curriculum Information
        </h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-6'>
            <Field
              type='checkbox'
              className='btn-check'
              name='curriculumType'
              value='1'
              id='kt_curriculum_diploma'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
              htmlFor='kt_curriculum_diploma'
            >
              <div className='symbol symbol-50px me-5'>
                <span className='symbol-label'>
                  <img
                    src={toAwsS3Url('/media/svg/curriculum/kurikulum-dp.svg')}
                    className='h-100 align-self-center'
                    alt=''
                  />
                </span>
              </div>
              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>Diploma</span>
                <span className='text-gray-400 fw-bold fs-6'>
                  IB
                </span>
              </span>
            </label>
          </div>

          <div className='col-lg-6'>
            <Field
              type='checkbox'
              className='btn-check'
              name='curriculumType'
              value='2'
              id='kt_curriculum_k13'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center'
              htmlFor='kt_curriculum_k13'
            >
              <div className='symbol symbol-50px me-5'>
                <span className='symbol-label'>
                  <img
                    src={toAwsS3Url('/media/svg/curriculum/kurikulum-k13.svg')}
                    className='h-100 align-self-center'
                    alt=''
                  />
                </span>
              </div>
              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>K13</span>
                <span className='text-gray-400 fw-bold fs-6'>
                  National Indonesia
                </span>
              </span>
            </label>
          </div>

          <div className='col-lg-6'>
            <Field
              type='checkbox'
              className='btn-check'
              name='curriculumType'
              value='3'
              id='kt_curriculum_middle_years'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
              htmlFor='kt_curriculum_middle_years'
            >
              <div className='symbol symbol-50px me-5'>
                <span className='symbol-label'>
                  <img
                    src={toAwsS3Url('/media/svg/curriculum/kurikulum-myp.svg')}
                    className='h-100 align-self-center'
                    alt=''
                  />
                </span>
              </div>
              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>Middle Years</span>
                <span className='text-gray-400 fw-bold fs-6'>
                  IB
                </span>
              </span>
            </label>
          </div>

          <div className='col-lg-6'>
            <Field
              type='checkbox'
              className='btn-check'
              name='curriculumType'
              value='4'
              id='kt_curriculum_paradigma_baru'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center'
              htmlFor='kt_curriculum_paradigma_baru'
            >
              <div className='symbol symbol-50px me-5'>
                <span className='symbol-label'>
                  <img
                    src={toAwsS3Url('/media/svg/curriculum/kurikulum-pb.svg')}
                    className='h-100 align-self-center'
                    alt=''
                  />
                </span>
              </div>
              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>Paradigma Baru</span>
                <span className='text-gray-400 fw-bold fs-6'>
                  National Indonesia
                </span>
              </span>
            </label>
          </div>

          <div className='text-danger mt-2'>
            <ErrorMessage name='curriculumType' />
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step3}

import {useEffect, useRef, FC, Children} from 'react'
import {MenuComponent} from '_metronic/assets/ts/components'
import {IPopupProps} from './Popup.model'
import clsx from 'clsx'
import {createPopper} from '@popperjs/core'

const Popup: FC<IPopupProps> = ({
  children,
  options = {},
  className = '',
  popUpTrigger = 'click',
  popUpPlacement = 'bottom-end',
  hideOnClick = true,
  isModal = false,
}) => {
  let childrenComponent = Children.toArray(children)
  const menuRef = useRef<any>(null)
  const menuItemRef = useRef<any>(null)
  useEffect(() => {
    let menu = MenuComponent.getInstance(menuRef.current)

    if (!menu) {
      new MenuComponent(menuRef.current, {
        ...options,
        dropdown: {
          ...options?.dropdown,
          hoverTimeout: options?.dropdown?.hoverTimeout || 200,
          zindex: options?.dropdown?.zindex || 105,
        },
        accordion: {
          ...options?.accordion,
          slideSpeed: options?.accordion?.slideSpeed || 250,
          expand: options?.accordion?.expand || false,
        },
      })
    }
  }, [options])

  return (
    <span
      onClick={(e) => {
        e.stopPropagation()
      }}
      className={clsx('d-inline-block', className)}
    >
      <span
        ref={menuItemRef}
        className='d-inline-block'
        data-kt-menu-trigger={popUpTrigger}
        data-kt-menu-placement={popUpPlacement}
        onClick={(e) => {
          if (!isModal) {
            e?.stopPropagation()
            const menu = MenuComponent.getInstance(menuRef.current)
            menu.show(menuItemRef.current)
          } else {
            createPopper(menuItemRef.current, menuRef.current, {
              placement: popUpPlacement,
              strategy: 'fixed',
            })
          }
        }}
      >
        {(childrenComponent && childrenComponent[0]) || <></>}
      </span>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 min-w-150px py-4'
        ref={menuRef}
        style={{
          width: 'fit-content',
          maxWidth: 'fit-content',
        }}
        onClick={() => {
          if (hideOnClick) {
            const menu = MenuComponent.getInstance(menuRef.current)
            menu.hide(menuItemRef.current)
          }
        }}
      >
        {(childrenComponent && childrenComponent[1]) || <></>}
      </div>
    </span>
  )
}

export default Popup

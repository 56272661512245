const COMPONENT_PAGE = '/components'
const AUTH_PAGE = '/auth'
const SELECT_ROLE = '/auth/selected-role'
const WELCOME_PAGE = '/welcome'
const ACCOUNT_PROFILE = '/profile'
const VERIFY_EMAIL = '/verify-email'
const PASSWORD_CONFIRMATION = '/password-confirmation'
const REGISTRATION_PAGE = '/registration'
const DASHBOARD_PAGE = '/dashboard'
const MANAGE_SETTING = '/manage-setting'
const GOOGLE_OAUTH_PAGE = '/oauth/google'

const MANAGE_SETTING_VIEW_PROFILE = `${MANAGE_SETTING}/profile`
const MANAGE_SETTING_UPDATE_PROFILE = `${MANAGE_SETTING_VIEW_PROFILE}/settings`

const PATH = {
  COMPONENT_PAGE,
  AUTH_PAGE,
  SELECT_ROLE,
  WELCOME_PAGE,
  ACCOUNT_PROFILE,
  VERIFY_EMAIL,
  PASSWORD_CONFIRMATION,
  REGISTRATION_PAGE,
  DASHBOARD_PAGE,
  MANAGE_SETTING,
  MANAGE_SETTING_VIEW_PROFILE,
  MANAGE_SETTING_UPDATE_PROFILE,
  GOOGLE_OAUTH_PAGE,
}
export default PATH

/* eslint-disable jsx-a11y/anchor-is-valid */
import {RootState} from 'setup'
import {toAwsS3Url} from '../../../../../_metronic/helpers'
import {useSelector} from 'react-redux'
import Styles from './SelectedRoleCustom.module.scss'
import clsx from 'clsx'

export function SelectedRoleCustom() {
  const theme = useSelector((state: RootState) => state.landing.theme)

  return (
    <div className='d-lg-flex flex-column h-100' style={{overflowY: 'auto'}}>
      <div className='flex-fill'></div>
      <div className='d-flex justify-content-center mt-20 mt-lg-0'>
        <img
          alt='Logo'
          src={theme.school_logo || toAwsS3Url('/media/logos/gk-main-logo.svg')}
          className={clsx(Styles.school_logo, 'mb-16')}
        />
      </div>
      <div className='text-center'>
        <h1 className='text-light' style={{fontSize: '28px'}}>
          Sign In
        </h1>
      </div>

      <div className='container'>
        <div className='row py-6'>
          <div className='col-lg-4 mb-6 mb-lg-0'>
            <a href='/teacher/auth/login' target='_self' className='mb-lg-12 cursor-pointer'>
              <div
                className={clsx(
                  'd-flex flex-column mb-5 pb-0 px-0 bg-light bg-opacity-50 bg-hover-opacity-100 h-100 overflow-hidden',
                  Styles.hoverCard
                )}
                style={{borderRadius: '16px'}}
              >
                <div className='text-dark fs-4 text-center mt-3 fw-bolder card-px'>Teacher</div>
                <div className='position-relative'>
                  <img
                    alt='Teacher Category'
                    src={toAwsS3Url('/media/illustrations/sigma_pro/cup.svg')}
                    style={{marginBottom: '-126px'}}
                    className='mw-100 w-100 mx-auto'
                  />
                </div>
              </div>
            </a>
          </div>
          <div className='col-lg-4 mb-6 mb-lg-0'>
            <a href='/student/auth/login' target='_self' className='mb-lg-12 cursor-pointer'>
              <div
                className={clsx(
                  'd-flex flex-column mb-5 pb-0 px-0 bg-light bg-opacity-50 bg-hover-opacity-100 h-100 overflow-hidden',
                  Styles.hoverCard
                )}
                style={{borderRadius: '16px'}}
              >
                <div className='text-dark fs-4 text-center mt-3 fw-bolder card-px'>Student</div>
                <div className='position-relative'>
                  <img
                    alt='Teacher Category'
                    src={toAwsS3Url('/media/illustrations/sigma_pro/shoppings.svg')}
                    style={{marginBottom: '-28px'}}
                    className='mw-100 w-100 mx-auto'
                  />
                </div>
              </div>
            </a>
          </div>
          <div className='col-lg-4 mb-6 mb-lg-0'>
            <a href='/parent/auth/login' target='_self' className='mb-lg-12 cursor-pointer'>
              <div
                className={clsx(
                  'd-flex flex-column mb-5 pb-0 px-0 bg-light bg-opacity-50 bg-hover-opacity-100 h-100 overflow-hidden',
                  Styles.hoverCard
                )}
                style={{borderRadius: '16px'}}
              >
                <div className='text-dark fs-4 text-center mt-3 fw-bolder card-px'>Parent</div>
                <div className='position-relative'>
                  <img
                    alt='Teacher Category'
                    src={toAwsS3Url('/media/illustrations/sigma_pro/cart.svg')}
                    style={{marginBottom: '-118px', transform: 'translateX(18px)'}}
                    className='mw-100 w-100 mx-auto'
                  />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className='text-center'>
        <div className='text-light fw-bold fs-6 fs-lg-8 mb-12 mb-lg-0'>
          Are you School Admin?
          <a
            className='link-primary fs-8 fw-bolder cursor-pointer'
            style={{marginLeft: '5px'}}
            href='/admin/auth/login'
            target='_self'
          >
            Log In here
          </a>
        </div>
      </div>

      <div className='flex-fill'></div>
      <div className='d-flex justify-content-center'>
        <img
          src={toAwsS3Url('/media/logos/Logo+GK+Powered.png')}
          alt='powerd by GKE'
          className='w-80px mb-6'
        />
      </div>
    </div>
  )
}

import {createReducer} from '@reduxjs/toolkit'
import {IThemeState, setTheme} from './index'

export const initialState: IThemeState = {
  theme: {
    id: 0,
    name: '',
    login_page_image: '',
    school_logo: '',
  },
}

const reducer = createReducer(initialState, (builder) =>
  builder.addCase(setTheme, (state, action) => {
    state.theme = action.payload.data
  })
)
export default reducer

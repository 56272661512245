
export const ActionTypes = {
  SET_STATUS_MAITANCE: 'SET_STATUS_MAITANCE',
}

export interface IStatusMaintance {
  status_maintance:boolean
  title: string
  message: string
}

import {put} from 'redux-saga/effects'
import {IActionRequestServiceProps} from './index'
import {apiRequestService} from 'app/services/apiRequest'
import {actionSuccess, actionFailure} from 'setup/redux/loading.toolkit'

function* sagaRequest({
  requestPayload,
  actionType,
  onSuccess,
  onFailure,
  actionPayload = {},
}: IActionRequestServiceProps) {
  try {
    const {data} = yield apiRequestService({...requestPayload, actionPage: actionType})
    if (data) {
      if (onSuccess) onSuccess({...(data || {}), ...actionPayload})
      yield put({
        type: actionSuccess(actionType),
        payload: {...(data || {}), ...actionPayload},
      })
      yield {...(data || {}), ...actionPayload}
    } else {
      if (onFailure) onFailure({})
      yield put({type: actionFailure(actionType)})
      yield undefined
    }
  } catch (err: any) {
    if (onFailure) onFailure(err?.response?.data)
    yield put({type: actionFailure(actionType)})
    yield undefined
  }
}
export default sagaRequest

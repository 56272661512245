const loadingReducer = (state = {}, action: any) => {
  const {type} = action
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type)

  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) return state

  const [, requestName, requestState] = matches
  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    [requestName]: requestState === 'REQUEST',
  }
}

const actionRequest = (action: string) => {
  return `${action}_REQUEST`
}
const actionSuccess = (action: string) => {
  return `${action}_SUCCESS`
}
const actionFailure = (action: string) => {
  return `${action}_FAILURE`
}
const createLoadingSelector =
  (...actions: any[]) =>
  (state: any): boolean => {
    // returns true only when all actions is not loading
    return actions.some((action) => state.loading[action])
  }

export {loadingReducer, actionRequest, actionSuccess, actionFailure, createLoadingSelector}

import React, {FC} from 'react'
import {toAwsS3Url} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {Field} from 'formik'

const Step5: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-8 pb-lg-10'>
        <h2 className='fw-bolder text-dark'>Your Are Done!</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please
          <Link to='/auth/login' className='link-primary fw-bolder'>
            {' '}
            Sign In
          </Link>
          .
        </div>
      </div>

      <div className='mt-5 mb-0'>
        <div>
          <div>
            <div className='timeline'>
              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>
                <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                  <div className='symbol-label bg-light'>
                    <span className='svg-icon svg-icon-2 svg-icon-gray-500'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                      >
                        <path
                          opacity='0.3'
                          d='M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z'
                          fill='black'
                        />
                        <path
                          d='M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z'
                          fill='black'
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className='timeline-content mb-10 mt-n1 overflow-hidden'>
                  <div className='mb-7 pe-3'>
                    <div className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Set Up Profile
                    </div>
                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>Account Details</div>
                    </div>
                  </div>
                  <div className='fv-row'>
                    <div className='row'>
                      <div className='col-lg-6 mb-7'>
                        <label className='form-label'>First Name</label>
                        <Field
                          disabled
                          name='firstName'
                          className='form-control form-control-lg form-control-solid'
                        />
                      </div>
                      <div className='col-lg-6 mb-7'>
                        <label className='form-label'>Last Name</label>
                        <Field
                          disabled
                          name='lastName'
                          className='form-control form-control-lg form-control-solid'
                        />
                      </div>
                      <div className='col-lg-12 mb-7'>
                        <label className='form-label'>Email</label>
                        <Field
                          disabled
                          name='email'
                          className='form-control form-control-lg form-control-solid'
                        />
                      </div>
                      <div className='col-lg-12'>
                        <label className='form-label'>Phone Number</label>
                        <Field
                          disabled
                          name='phoneNumber'
                          className='form-control form-control-lg form-control-solid'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>
                <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                  <div className='symbol-label bg-light'>
                    <span className='svg-icon svg-icon-2 svg-icon-gray-500'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                      >
                        <path
                          opacity='0.3'
                          d='M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z'
                          fill='black'
                        />
                        <path
                          d='M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z'
                          fill='black'
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className='timeline-content mb-10 mt-n1 overflow-hidden'>
                  <div className='mb-7 pe-3'>
                    <div className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Set Up School
                    </div>
                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>School Information Details</div>
                    </div>
                  </div>
                  <div className='fv-row'>
                    <div className='row'>
                      <div className='col-lg-12 mb-7'>
                        <label className='form-label'>School Name</label>
                        <Field
                          disabled
                          name='schoolName'
                          className='form-control form-control-lg form-control-solid'
                        />
                      </div>
                      <div className='col-lg-12 mb-7'>
                        <label className='form-label'>School Address</label>
                        <Field
                          disabled
                          as='textarea'
                          name='schoolAddress'
                          className='form-control form-control-lg form-control-solid'
                          rows={3}
                        ></Field>
                      </div>
                      <div className='col-lg-6 mb-7'>
                        <label className='form-label'>Province</label>
                        <Field
                          disabled
                          name='province'
                          className='form-control form-control-lg form-control-solid'
                        />
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label'>City / Region</label>
                        <Field
                          disabled
                          name='city'
                          className='form-control form-control-lg form-control-solid'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>
                <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                  <div className='symbol-label bg-light'>
                    <span className='svg-icon svg-icon-2 svg-icon-gray-500'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                      >
                        <path
                          opacity='0.3'
                          d='M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z'
                          fill='black'
                        />
                        <path
                          d='M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z'
                          fill='black'
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className='timeline-content mb-10 mt-n1 overflow-hidden'>
                  <div className='mb-7 pe-3'>
                    <div className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Set Up Curriculum Details
                    </div>
                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>Curriculum Detail</div>
                    </div>
                  </div>
                  <div className='fv-row'>
                    <div className='row'>
                      <div className='col-lg-6 mb-7'>
                        <div className='d-flex align-items-sm-center'>
                          <div className='symbol symbol-40px me-5'>
                            <span className='symbol-label'>
                              <img
                                src={toAwsS3Url('/media/svg/curriculum/kurikulum-dp.svg')}
                                className='h-100 align-self-center'
                                alt=''
                              />
                            </span>
                          </div>
                          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                            <div className='flex-grow-1 me-2'>
                              <div className='text-gray-800 text-hover-primary fs-6 fw-bolder'>
                                Diploma
                              </div>
                              <span className='text-muted fw-bold d-block fs-7'>IB</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-6 mb-7'>
                        <div className='d-flex align-items-sm-center'>
                          <div className='symbol symbol-40px me-5'>
                            <span className='symbol-label'>
                              <img
                                src={toAwsS3Url('/media/svg/curriculum/kurikulum-k13.svg')}
                                className='h-100 align-self-center'
                                alt=''
                              />
                            </span>
                          </div>
                          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                            <div className='flex-grow-1 me-2'>
                              <div className='text-gray-800 text-hover-primary fs-6 fw-bolder'>
                                K13
                              </div>
                              <span className='text-muted fw-bold d-block fs-7'>
                                National Indonesia
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-6 mb-7'>
                        <div className='d-flex align-items-sm-center'>
                          <div className='symbol symbol-40px me-5'>
                            <span className='symbol-label'>
                              <img
                                src={toAwsS3Url('/media/svg/curriculum/kurikulum-myp.svg')}
                                className='h-100 align-self-center'
                                alt=''
                              />
                            </span>
                          </div>
                          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                            <div className='flex-grow-1 me-2'>
                              <div className='text-gray-800 text-hover-primary fs-6 fw-bolder'>
                                Middle Years
                              </div>
                              <span className='text-muted fw-bold d-block fs-7'>IB</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-6 mb-7'>
                        <div className='d-flex align-items-sm-center'>
                          <div className='symbol symbol-40px me-5'>
                            <span className='symbol-label'>
                              <img
                                src={toAwsS3Url('/media/svg/curriculum/kurikulum-pb.svg')}
                                className='h-100 align-self-center'
                                alt=''
                              />
                            </span>
                          </div>
                          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                            <div className='flex-grow-1 me-2'>
                              <div className='text-gray-800 text-hover-primary fs-6 fw-bolder'>
                                Paradigma Baru
                              </div>
                              <span className='text-muted fw-bold d-block fs-7'>
                                National Indonesia
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>
                <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                  <div className='symbol-label bg-light'>
                    <span className='svg-icon svg-icon-2 svg-icon-gray-500'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                      >
                        <path
                          opacity='0.3'
                          d='M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z'
                          fill='black'
                        />
                        <path
                          d='M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z'
                          fill='black'
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className='timeline-content mb-10 mt-n1 overflow-hidden'>
                  <div className='mb-7 pe-3'>
                    <div className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      School Information
                    </div>
                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>School Detail</div>
                    </div>
                  </div>
                  <div className='fv-row'>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <label className='form-label'>Number Of Student</label>
                        <Field
                          disabled
                          name='numberStudent'
                          className='form-control form-control-lg form-control-solid'
                        />
                      </div>
                      <div className='col-lg-12'>
                        <label className='form-label'>Number Of Teacher / Staff</label>
                        <Field
                          disabled
                          name='numberStaff'
                          className='form-control form-control-lg form-control-solid'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step5}

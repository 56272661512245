import {toAwsS3Url} from '_metronic/helpers'
import {ITheme} from 'app/modules/auth/redux/landing-page'
import clsx from 'clsx'
import {FC} from 'react'
import Styles from './CustomLayout.module.scss'

const CustomLayout: FC<{children: JSX.Element[] | JSX.Element; theme: ITheme}> = ({
  children,
  theme,
}) => {
  return (
    <div
      className='w-100 h-100 position-relative'
      style={{
        backgroundImage: `url(${
          theme.login_page_image || toAwsS3Url('/media/bg-image/Gambar+Background.png')
        })`,
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
      }}
    >
      <div className={clsx(Styles.overlay)}></div>
      <div
        className='position-fixed top-0 end-0 w-100 mw-500px h-100 overflow-y-auto'
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.20)',
          filter: 'drop-shadow(46px 46px 40px rgba(0, 0, 0, 0.25))',
          backdropFilter: 'blur(25px)',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default CustomLayout

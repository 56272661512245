import {IApiRequestServiceProps} from './apiRequest.model'
import axios from 'axios'
import {isDemo, isProduction, isDevelopment} from '../environment'

const API_URL = process.env.REACT_APP_API_URL
const DEMO_API_URL = process.env.REACT_APP_DEMO_API_URL

export function apiRequestService({
  method,
  url,
  data,
  contentType = 'application/json',
  baseURL = '',
  actionPage = '',
  isDataPayload = false,
}: IApiRequestServiceProps) {
  const dataOrParams =
    !isDataPayload && ['get', 'delete'].includes(method.toLowerCase()) ? 'params' : 'data'
  let baseApiUrl = '/api'
  baseApiUrl = API_URL || ''
  if (!isDevelopment()) {
    baseApiUrl = API_URL || ''
    if (!isProduction() && DEMO_API_URL && isDemo()) {
      baseApiUrl = DEMO_API_URL
    }
  }
  return axios.request({
    url: url,
    baseURL: baseURL || baseApiUrl,
    method,
    headers: {
      xsitecode:
        window.location.hostname !== 'localhost'
          ? window.location.hostname
          : 'staging-admin-school.gurukreator.id',
      'content-type': contentType,
      'action-page': actionPage,
      'url-page': window?.location?.href || window?.location?.pathname || '',
    },
    [dataOrParams]: data,
  })
}
